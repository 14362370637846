import './App.css';
import { SocialIcon } from 'react-social-icons';
import { Experience, Project } from './components';

// TODO: Fonts, hover for social_icons, then footer, padding for .App on huge screens

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="App">
      <div className="section" id="welcome">
        <div>
          <h1>Hi, I'm Parth 👋</h1>
          <p className="heavy-p">Frontend Web Dev</p>
        </div>
        <div id="socials">
          <p>Get in touch</p>
          <div>
            <SocialIcon url='https://github.com/thecodingaviator' bgColor='#07080a' fgColor='#f7f7f7' target='_blank' rel='noopener noreferrer' />
            <SocialIcon url='https://www.linkedin.com/in/hiparthparth/' bgColor='#07080a' fgColor='#f7f7f7' target='_blank' rel='noopener noreferrer' />
            <SocialIcon url='https://codepen.io/thecodingaviator' bgColor='#07080a' fgColor='#f7f7f7' target='_blank' rel='noopener noreferrer' />
          </div>
        </div>
      </div>
      <div className="section" id="information">
        <p id="introduction">
          I have been developing web apps since 2019 when I was first introduced to <a className="custom-link" href="https://web.archive.org/web/20240829013059/https://opensource.com/resources/what-open-source">Open Source Software</a> through Hacktoberfest.
          My journey started with the <a href="https://github.com/freeCodeCamp/freeCodeCamp">freeCodeCamp</a> project, where I went on to become a project moderator, interacting with a community of over 4,000 members.
          Since then, I have worked on all kinds of stuff from data dashboards and program websites to full web apps for companies.
          <br /><br />
          Some of the technologies I have worked with includes React, Express, and TypeScript. Besides those, I have worked with Python, Node, and mySQL on the backend. I'm learning <a href="https://www.prisma.io/">Prisma</a> lately and I'm excited to see where it takes me.
          <br /><br />
          In my free time, I like to contribute to open-source projects, write browser extensions to make life easier, and learn new technologies. When I'm not around a computer, I'm either <a href="https://wmhbradio.org/">DJing for a radio show 📻</a>, working on my pilot's license ✈️, or <a href="https://www.alltrails.com/explore/list/my-favourite-hikes-0332d69">out and about ⛰️</a>.
        </p>
        <h2 className="title">Experience</h2>
        <Experience
          position="Software Engineering Intern"
          name="Authentic Insurance"
          descriptions={[
            <p>Added tracking features aiming at user retention and led project to add insurance features.</p>,
            <p>Worked closely with business development to reduce user friction on the website.</p>,
          ]}
          dates="Jun 2023 - Aug 2023"
          link="https://authenticinsurance.com"
        />
        <Experience
          position="Open Source Dev + Maintainer"
          name="freeCodeCamp"
          descriptions={[
            <p>Contributed 13,000+ lines of code and moderated the GitHub repo, overseeing organic growth from 250k to 334k stars, interacting with <a href="https://github.com/freeCodeCamp/freeCodeCamp/issues?q=commenter%3Athecodingaviator+">1,700+ contributions</a>.</p>,
            <p>Co-led site-wide migration to TypeScript for the online platform with ~2.5M views a month.</p>,
            <p>Mentored a community of over 4,300 people, reviewing and adding contributions to the project, taking it to 27k forks.</p>
          ]}
          dates="Mar 2019 - May 2023"
          link="https://github.com/freeCodeCamp/freeCodeCamp"
        />
        <Experience
          position="Fullstack Web Developer"
          name="Bigelow Lab for Ocean Sciences"
          descriptions={[
            <p>Designed and implemented a web dashboard, boosting project collaboration amongst 30+ researchers and staff at 10+ institutions.</p>,
            <p>Leveraged Google Drive and DigitalOcean Droplets to implement file sharing, improving efficiency by 50%.</p>,
            <p>Developed a robust data UI, streamlining and standardizing data sharing amongst researchers from varied academic disciplines.</p>
          ]}
          dates="May 2023 - Dec 2023"
          link="https://www.bigelow.org/"
        />
        <h2 className="title">Projects</h2>
        <Project name="Passgen Extension"
          description={<p>I switched from Chrome to Brave recently, but I miss the password generator that Chrome has built-in. So, instead of using a third-party extension, I built my own.</p>}
          link="https://github.com/thecodingaviator/passgen"
        />
        <Project name="URL Meta Scraper"
          description={<p>There existed no JS tools to scrape metadata from a URL, so I created an Express REST API to do just that. There are PHP and Python solutions, but I did not want to add that complexity.</p>}
          link="https://github.com/thecodingaviator/get-url-meta"
        />
        <Project name="Arduino Alarm Beacon"
          description={<p>Over my internship at Authentic Insurance, I built a simple beacon alarm system using an Arduino microcontroller and a beacon 🚨 that goes off whenever a policy is sold.</p>}
          link="https://github.com/thecodingaviator/authentic-policy-alarm"
        />
        <footer>
          <p>Made with <span style={{ color: 'red' }}>❤️</span> by <a href="https://github.com/thecodingaviator">Parth Parth</a>. © {currentYear}</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
